@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2&family=Itim&display=swap');
@font-face {
    font-family: 'opunregular';
    src: url('./pages/fonts/opun-webfont.woff2') format('woff2'),
         url('./pages/fonts/opun-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+0E00-U+0E7F;
}
*{
    font-family: 'opunregular';
    /*font-family: 'Baloo Bhai 2', cursive;*/
}
.container {
    justify-content: flex-start;
}

