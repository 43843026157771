*{
    box-sizing: border-box;
}
body{
    margin: 0;

}

.slogan{
    font-size: 1.2rem;
    text-align: center;
    visibility: hidden;
    animation:movedown 0.5s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
}

.main a{
    text-decoration: None;
    font-size: 1rem;
    font-weight: 400;
    color: #6050a8;
    transition: 0.4s ease-out;

}
.head{
    margin: 0 auto;
    position: relative;
    align-items: center;
    justify-content: space-around;
    width: 30rem;
    display:flex;
    border-radius: 2rem;
    background-color: #AFB4ff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    visibility: hidden;
    animation:movedown 0.7s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    z-index: 1;
}
.home-head p{
    font-size: 1.5rem;
}
.dropdown{
    font-size: 1.5rem;
    padding: 0 1.5rem;
    
}
.main{
    position: relative;
    background-color: #DDDFFF;
    display: inline-block;
    border-radius: 2rem ;
    margin:0 auto;
    margin-top:-1rem ;
    padding: 2rem;
    visibility: hidden;
    animation:movedown 0.7s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;

}
.btn-container1{
    display: flex;
    justify-content: space-between;
    padding: 0rem 5rem;
    visibility: hidden;
    animation:movedown 1s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
   
}
.btn-container2{
    display: flex;
    justify-content: space-between;
    padding: 0rem 5rem;
    visibility: hidden;
    animation:movedown 1s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
}
.btn-kmutnb{
    display:inline-block;
    background-color: rgb(255, 246, 167);
    border-radius: 2rem;
    width: 25rem;
    margin:1rem 5rem;
    text-align: center;
    justify-content: space-between;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     
}
.btn-kmitl{
    display:inline-block;
    background-color: rgb(255, 246, 167);
    border-radius: 2rem;
    width: 25rem;
    margin:1rem 5rem;
    text-align: center;
    justify-content: space-between;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     
}
.btn-kmutt{
    display:inline-block;
    background-color: rgb(255, 246, 167);
    border-radius: 2rem;
    width: 25rem;
    margin:1rem 5rem;
    text-align: center;
    justify-content: space-between;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     
}
.btn-tu{
    display:inline-block;
    background-color: rgb(255, 246, 167);
    border-radius: 2rem;
    width: 25rem;
    margin:1rem 5rem;
    text-align: center;
    justify-content: space-between;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     
}


.main img{
    width: 8rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    float: left;
   
}
@keyframes movedown {
    0%{
        transform:translateY(-10px);
        visibility: visible;
    }
    100%{
        transform: translateY(0);
        visibility: visible;
    }
    
}
@media all and (max-width : 720px){

    .head{
        display: block;
        justify-content: center;
        padding: 0 6rem;
    
    }
    .head h1{
        font-size: 3vw;
        display:flex;
    }
    .dropdown{
        padding: 0 3vw;
        font-size: 3vw;
        
    }
    .btn-container1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .slogan{
        font-size: large;
    }
    .btn-container2{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .btn-kmitl,.btn-kmutnb,.btn-kmutt,.btn-tu{
        width: 40vw;
        padding: 0.2vw 1vw;
        margin: 1vw 1vw;
        
    }
    .main{
        padding: 1rem 0 1rem ;
       
        height: auto;
    }
    .main a,.main img{
        font-size: 3vw;
        width: 12vw;
    }
}
