*{
    box-sizing: border-box;
}
body{
    margin: 0;
    
}

.container {
    text-align: center;
}
.hamburger{
    color: white;
}
.navcontainer img{
    padding: 0.5rem 0 0 1rem;
    width:9rem;
    display: flex;
}
.navcontainer{
    display: flex;
    background-color:  #6050a8;
    justify-content: space-between;
    align-items: stretch;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}



nav ul{
    padding:1rem ;
    margin: 0;
    list-style: None;
    display: flex;
    gap: 4.5rem;
    font-size: 1rem;

}
nav li{
    padding-top: 0.2rem;
}
nav a{
    color: white;
    text-decoration: none;
    height: 100%;
    padding:.25rem;
    align-items: center;
    transition:  0.3s ease-in;
    
    
}

.dropdown-nav{
    overflow: hidden;
}
.dropbtn:hover{
    background-color: #fff77c;
    transition: 0.3s ease-in;
}
.dropbtn{
    display: flex;
    align-items: center;
    border-radius: 2rem;
    padding: 0 1rem;
    background: none;
    border: 1.5px solid grey;
    font-size: 1rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin:0;
    cursor: pointer;
    background-color: white;
}
#arrowicon{
    font-size:1.2rem
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
.dropdown-content a {
    font-size: 1rem;
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}
.dropdown-content a:hover {
    background-color: #fff77c;
    color: black;
}
  
.dropdown-nav:hover .dropdown-content {
    display: block;
}
.login-tab1{
    display:flex;
    border:2px solid grey;
    border-radius: 2rem;
    justify-content: space-between;
    font-size: 1rem;
    align-items: center;
    transition:  0.3s ease-out;
    background-color: white;
}
.login-tab1 a{
    color: black;
}
#signup{
    font-size: 1rem;
}
.userprofile{
    display: inline;
    align-items: center;
    border-radius: 2rem;
    margin: 0 0.4rem;
    padding:0vw 1rem;
    cursor: pointer;
}

.userprofile a:hover{
    color: black;
   
}
.userprofile:hover{
    background-color: #fff77c;
}

.logout{
    background: none;
    border: none;
    font-size: 1rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition:  0.3s ease-in;
    margin: 0;
}

nav a:hover,a:focus {
    color:#fff77c
    }

.nav-toggle{
    display: none;
    position: absolute;
    top: 1.5rem;
    right: 1rem;

}

.nav-toggle{
    font-size: 6vw;
}
@media all and (max-width : 1085px){
    #signup{
        font-size: 0;
        transition:  0.3s ease-out;
        text-indent:100%;
        white-space: nowrap;
        overflow: hidden;
        margin: 0 0rem;
        padding: 0;
    }
    #User{
        font-size: 0;
        transition:  0.3s ease-out;
    }
    #user-icon{
        font-size: 1rem;
    }
    .logout{
        font-size: 0;
    }
    #logout{
        text-indent:100%;
        white-space: nowrap;
        overflow: hidden;
        font-size: 1.5rem;
        margin-left: 2rem;
    }

}
@media all and (max-width : 960px){
    #login{
        text-indent:100%;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0;
        transition:  0.3s ease-out;
    }
    #user-icon{
        font-size: 1rem;
    }
    .userprofile{ 
        margin: 0 0rem;
        padding: 0;
        
    }
    .login-tab1{
        transition:  0.3s ease-out;
        border: none;
        margin: 0;
    }

}
@media all and (max-width : 840px){
    .navcontainer{
        flex-direction:column;
        align-items: flex-start;
        }
        
    .close{
        height: 0vh;
        overflow: hidden;
        
 
    } 
    nav a{
        color: black;
        transition: all 0.3s ease-out;
    }

    nav a:hover,a:focus {
    color: #333;
    }

    nav{
        width: 100%;
        
    }   
    .navcontainer img{
        width: 25vw;
        margin-left: 33vw;
    }
    nav ul{
        display: block;
        text-align: center;
        padding: 0;
        margin-bottom: 5vw;

    }
    nav li{
        padding: 1.5vw ;
        margin:1.5vw 25vw;
        border-radius: 6rem;
        font-size: 3vw; 
        background-color: #AFB4ff;
    }
    nav li:hover{
        background-color: #fff77c;
        transition:  0.3s ease-in;
    }
    .nav-toggle{
        display: block;
    }
    .dropbtn{
        background-color: #AFB4ff;
        display: flex;
        justify-content: center;
        padding: 1.5vw 17vw ;
        margin:0;
        border-radius: 6rem;
        font-size: 3vw;
    }
    .dropbtn:hover{
        color: black;
        transition:  0.3s ease-in;

    }
    .userprofile{
        display: block;
        padding: 1vw 5vw ;
        margin:1vw 25vw;
        border-radius: 6rem;
        font-size: 3vw; 
        background-color: #AFB4ff;
        color: white;
        transition:  0.3s ease-out;

    }
    .userprofile:hover{
        background-color: #fff77c;
    }
    .logout{
        font-size: 3vw;
        color: black;
    }
    .login-tab1{
        display:block;
        background-color:#6050a8;
        border: none;
        border-radius: 3vw;
        justify-content: space-between;
        font-size: 3vw;
        align-items: center;
        padding: 0 0.1vw ;
    
    }
    #login{
        font-size: 3vw;
    }
    #signup{
        font-size: 3vw;
    }
    #signup:hover{
        color: black;
    }
    

}
