.select-container{
    display: flex;
    justify-content: center;
    gap:2rem
}
.select-container Select{
    width: 10rem;
}
.post-container{
    display: inline-block;
    justify-content: center;
}
.post-container h1 {
    margin:0

}
.row{
    width: 1000px;
    border-radius: 2rem;
    margin-bottom: 3rem;

}
.column{
    display: flex;
    align-items: center;
}
.column img{
    border-radius: 2rem;
    width: 390px;
    height: 330px ;


}
.info{
    text-align: start;
    margin-left: 1rem;
    font-size:14px;
}

.info h1{
    font-size: 1.5rem;
    
}
.select{
    background:none;
    border: none;
    text-align: end;
    padding:0.5rem 3rem;
    font-size: 1rem;
    border: none;
    border-radius: 2rem;
    background-color: rgba(241, 228, 113, 0.67);
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 13rem;
}
.but button{
    cursor: pointer;
    border-radius: 1rem;
    border:2px solid;
    background-color:rgb(255, 246, 167);
    font-size: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
#delete-button{
    background-color: red;
    color: white;
    border:2px solid black;
}
.edit{
    display: flex;
    justify-content: center;
    
}
.imagedit{
    padding-right:5rem;

}
@media all and (max-width : 720px){
    .row{
        border-radius: 2rem;
        margin: 1.5rem 9vw
    }
    .column{
        display: block;
    }
    .column img{
        width: 100%;
        height: auto;

    }
    .interest{
        text-align:center;
    }
    .info{
        font-size:3vw;
    }
}
