.container{
    display: flex;
    justify-content: center;
    margin: 0;
    position: relative;
    z-index: 0;
}
.head-container{
    margin-top: 1rem;
    position: relative;
    display:flex;
    justify-content: center;
    z-index: 1;
}
.head{
    display:flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 40%;
    border-radius: 2rem;
    background-color: #AFB4ff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    visibility: hidden;
    animation:movedown 0.7s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
}
.head h1{
    margin:1.3rem;
    font-size: 1.3rem;
}
.form_container{
    display:flex;
    justify-content: center;
    width: 100%;
    margin-top: -2.5rem;
    padding: 4rem 15rem;
    background-color:#DDDFFF;
    border-radius: 2rem;
    visibility: hidden;
    animation:movedown 0.5s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
}
label{
    display: flex;
    justify-content: start;
    font-size: 0.9rem;
    visibility: hidden;
    animation:movedown 0.7s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    
}
input[type="text"],input[type="password"] ,textarea ,input[type="number"],input[type="tel"]{
    padding: 1rem 4rem;
    display: flex;
    border-radius: 2rem;
    border:0;
    background-color : rgb(255, 246, 167); 
    width: 100%;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="submit"] {
    color: white;
    font-size: 1.2rem;
    border:none;
    padding: 0.5rem;
    background-color : #BF95FF;; 
    border-radius: 3vw;
    cursor: pointer;
   
  
  }
.input img{
    width: 50vw;
}
.submit{
    display: flex;
    justify-content: center;
    padding:1rem;
}
.btn-login{
    text-align: center;
    padding: 2rem 0 2rem 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}
.sign {
    display: flex;
    padding:0;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.1rem;
}
input,.sign{
    visibility: hidden;
    animation:movedown 0.9s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
}
.react-select{
    margin-bottom: 1rem;
}
#ifYes{
    display: none;
}