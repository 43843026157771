.form_container{
    padding: 0px;
}

.form_container {
    display: block;
    font-style:normal;
}
*{
    font-family: 'opunregular';
    /*font-family: 'Baloo Bhai 2', cursive;*/
}
