*{
    box-sizing: border-box;
}
body{
    margin: 0;
}
.header{
    color:  #FFFFFF;;
    position: relative;
    margin: 1rem 20rem 0 ;
    padding: 1.5rem 6rem;
    background-color: #AFB4ff;
    border-radius: 4rem;
    font-size: 1.5rem;
    text-align: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index:1;
    visibility: hidden;
    animation:movedown 0.5s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
}
.background{
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    padding: 3rem;
    margin:0 4.5rem;
    margin-top: -3rem;
    margin-bottom: 3rem;
    display: block;
    background-color: #DDDFFF;
    border-radius: 3rem;
    visibility: hidden;
    animation:movedown 0.7s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    

}


.facebook{
    color:  #6050a8;
    justify-content: center;
    display: flex;
    align-items: center;
    gap:0.5rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 25rem;
    padding: 1.5rem  ;
    font-size: 1.5rem;
    background-color: rgb(255, 246, 167);
    border-radius: 3rem;
    visibility: hidden;
    animation:movedown 1.2s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.facebook img{
    display: block;
    padding-left: auto;
    padding-right:auto;
    width: 4.5rem; 
}

.line{
    color:  #6050a8;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 2.5rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 25rem;
    padding: 1.5rem  ;
    font-size: 1.5rem;
    background-color: rgb(255, 246, 167);
    border-radius: 3rem;
    visibility: hidden;
    animation:movedown 1.2s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.line img{
    display: block;
    padding-left: auto;
    padding-right:auto;
    width: 4rem; 
}
.call{
    color:  #6050a8;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 25rem;
    padding: 1.5rem  ;
    font-size: 1.5rem;
    background-color: rgb(255, 246, 167);
    border-radius: 3rem;
    visibility: hidden;
    animation:movedown 1.2s linear 1;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.phone{
    font-size: 2.5rem;

}
@keyframes movedown {
    0%{
        transform:translateY(-10px);
        visibility: visible;
    }
    100%{
        transform: translateY(0);
        visibility: visible;
    }
}
@media all and (max-width : 720px){

}